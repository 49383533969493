/**=====================
    3.25 Knowledgebase CSS start
==========================**/
.browse {
  .browse-articles {
    span {
      vertical-align: middle;
      margin-right: 5px;
      &:nth-child(2) {
        padding-left: 20px;
        [dir="rtl"] & {
          padding-left: unset;
          padding-right: 20px;
        }
      }
      svg {
        width: 14px;
        height: 20px;
        vertical-align: text-top;
        position: absolute;
        left: 0;
        top: 0;
        [dir="rtl"] & {
          left: unset;
          right: 0;
        }
      }
    }
    h4 {
      font-weight: 600;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eee;
      color: #59667a;
      padding-left: 25px;
      position: relative;
      line-height: 1.6;
      font-size: 16px;
      [dir="rtl"] & {
        padding-left: unset;
        padding-right: 25px;
      }
      span {
        svg {
          width: 16px;
          height: 16px;
          vertical-align: text-top;
          top: 4px;
          left: 7px;
          [dir="rtl"] & {
            left: unset;
            right: 5px;
          }
        }
      }
    }
    ul {
      li {
        padding: 8px;
        border-radius: 8px;
        a {
          color: $theme-body-font-color;
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        &:hover {
          background-color: $light;
        }
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  .browse {
    div[class^="col-"] {
      &:nth-child(3) {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .browse {
    .browse-articles {
      ul {
        li {
          a {
            span {
              &:nth-child(2) {
                width: 158px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
.badge {
  &.pull-right {
    padding-top: 6px;
  }
}
/**=====================
    3.25 Knowledgebase CSS End
==========================**/