/**=====================
2.6 Breadcrumb CSS Start
==========================**/
.breadcrumb-colored {
	padding: 12px 16px;
	border-radius: 4px;
	li {
		a {
			color: $breadcrumb-ancher-color;
		}
		&.active {
			color: $breadcrumb-ancher-color;
			opacity: 0.7;
		}
	}
	.breadcrumb-item {
		&:before {
			color: $breadcrumb-ancher-color;
		}
	}
	.breadcrumb-item.txt-dark {
		&:before {
			color: $breadcrumb-ancher-dark-color;
		}
	}
}

.breadcrumb-icon {
	--bs-breadcrumb-divider: '>';

	li {
		display: inline-block;
	}
}

.breadcrumb-no-divider {
	--bs-breadcrumb-divider: '';
}

.breadcrumb-item {
	&.active {
		text-transform: capitalize;

	}
}

.breadcrumb-space {
	.breadcrumb-item {
		&::before {
			padding-right: 0;
		}
	}
}

	.tab-breadcrumb-ui{
		display: inline-block;
		box-shadow: 0 0 8px 1px #00000059;
		overflow: hidden;
		border-radius: 5px;
		counter-reset: flag;
   }
   .tab-breadcrumb-ui a{
	text-decoration: none;
	outline: none;
	display: block;
	float: left;
	font-size: 12px;
	line-height: 36px;
	color:#fff;
	padding: 0 10px 0 36px;
	background: #666;
	background: linear-gradient(#666, #333);
	position: relative;
	cursor: pointer;
   }
   .tab-breadcrumb-ui a:first-child{
	// padding-left: 46px;
	border-radius: 5px 0 0 5px;
   }
   .tab-breadcrumb-ui a:first-child::before{
	left: 14px;
   }
   .tab-breadcrumb-ui a:last-child{
	border-radius: 0 5px 5px 0;
	padding-right: 20px;
   }
   .tab-breadcrumb-ui a.active, .tab-breadcrumb-ui a:hover{
	background: #333;
	background: linear-gradient(#333, #000);
   }
   .tab-breadcrumb-ui a.active::after, .tab-breadcrumb-ui a:hover::after{
	background: #333;
	background: linear-gradient(135deg, #333, #000);
   }
   .tab-breadcrumb-ui a::after{
	content: "";
	position: absolute;
	top: 0;
	right: -18px;
	width: 36px;
	height: 36px;
	transform: scale(0.707) rotate(45deg);
	z-index: 1;
	background: #666;
	background: linear-gradient(135deg, #666, #333);
	box-shadow: 
	2px -2px 0 -1px #00000066,
	3px -3px 0 2px #ffffff1a;
	border-radius: 0 5px 0 50px;
   }
   .tab-breadcrumb-ui a:last-child::after{
	content: none;
   }
//    .tab-breadcrumb-ui a::before{
// 	content: counter(flag);
// 	counter-increment: flag;
// 	border-radius: 100%;
// 	width: 20px;
// 	height: 20px;
// 	line-height: 20px;
// 	margin: 8px 0;
// 	position: absolute;
// 	top: 0;
// 	left: 30px;
// 	background: #444;
// 	background: linear-gradient(#444, #222);
// 	font-weight: bold;
//    }
   .flat a, .flat a::after{
	background: #fff;
	color: #000;
	transition: all 0.5s;
   }
   .flat a::before{
	background: #fff;
	box-shadow: 0 0 0 1px #ccc;
   }
   .flat a:hover, .flat a.active,
   .flat a:hover::after, .flat a.active::after{
	background: #33bfbf;
    color: white;
   }

   .flat a[disabled], .flat a:hover::after {
	color: #999 !important; /* Text color for disabled state */
	background-color: #dddddd0a !important; /* Background color for disabled state */
	cursor: not-allowed; /* Change cursor to indicate not-allowed action */
	/* Add any other styles you want for the disabled state */
  	}
   
/**=====================
	2.6 Breadcrumb CSS ends
==========================**/