/**=====================
    3.11 General widget CSS Start
==========================**/
.currency-widget {
  .currency-icon-widget {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: inset 2.66667px -2.66667px 2.66667px rgba(194, 128, 49, 0.1), inset -2.66667px 2.66667px 2.66667px rgba(255, 255, 255, 0.1);
    svg {
      width: 16px;
      height: 16px;
      fill: $white;
    }
  }
  .card {
    margin-top: 14px;
    .card-body {
      padding: 10px;
      gap: 10px;
      justify-content: space-between;
      @media (max-width:1660px) {
        flex-wrap: wrap;
      }
      @media (max-width:575px) {
        flex-wrap: unset;
      }
    }
    &:hover {
      transform: translateY(-5px);
      transition: 0.5s;
    }
  }
  h6 {
    margin-bottom: 0;
    margin-bottom: 0;
    line-height: 1.2;
    font-size: 16px;
  }
  .d-flex {
    gap: 10px;
    align-items: center;
  }
  span {
    svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  div[class*="bg-light-"] {
    color: $theme-body-font-color;
    padding: 15px;
    border-radius: 10px;
    width: 115px;
    h5 {
      font-size: 20px;
      font-weight: 700;
    }
    @media (max-width:1660px) and (min-width: 1200px) {
      display: none;
    }
    @media (max-width: 1199px) {
      width: 100%;
    }
    @media (max-width: 575px) {
      width: 50%;
    }
  }
  &.widget-currency {
    .card {
      .card-body {
        @media (max-width: 1199px) and (min-width: 768px) {
          flex-wrap: unset;
        }
      }
    }
  }
  &.warning {
    .currency-icon-widget {
      background-image: linear-gradient(to right, $warning-color 80%, rgba($warning-color, 0.2));
    }
  }
  &.primary {
    .currency-icon-widget {
      background-image: linear-gradient(to right, $primary-color 80%, rgba($primary-color, 0.2));
    }
  }
  &.success {
    .currency-icon-widget {
      background-image: linear-gradient(to right, $success-color 80%, rgba($success-color, 0.2));
    }
  }
  &.height-equal {
    @media (max-width:1399px) {
      min-height: unset !important;
    }
  }
}
.square-group {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(-1);
  [dir="rtl"] & {
    right: unset;
    left: 0;
  }
  .square-1 {
    width: 12px;
    height: 18px;
  }
  .square-2 {
    width: 12px;
    height: 10px;
    margin-bottom: -10px;
  }
  .square-3 {
    width: 18px;
    height: 10px;
    margin-left: 10px;
    [dir="rtl"] & {
      margin-left: unset;
      margin-right: 10px;
    }
  }
  .square-4 {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: -15px;
    [dir="rtl"] & {
      right: unset;
      left: -15px;
    }
  }
  .square-5 {
    width: 6px;
    height: 6px;
    bottom: 22px;
    position: absolute;
    right: -1px;
    [dir="rtl"] & {
      right: unset;
      left: -1px;
    }
  }
  .square-6,
  .square-7 {
    position: absolute;
    width: 4px;
    height: 4px;
  }
  .square-6 {
    right: -23px;
    bottom: 5px;
    [dir="rtl"] & {
      right: unset;
      left: -23px;
    }
  }
  .square-7 {
    right: 8px;
    bottom: 45px;
    [dir="rtl"] & {
      right: unset;
      left: 8px;
    }
  }
  .warning1 {
    background: #FFD497;
  }
  .warning {
    background: #FFA73B;
  }
  .primary {
    background: $primary-color;
  }
  .danger {
    background: #f54132;
  }
  .light {
    background: #E6E8EF;
  }
  .success {
    background: $success-color;
  }
}
.balance-item {
  display: flex;
  gap: 10px;
  .balance-icon-wrap {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    @media (max-width: 1660px) {
      width: 25px;
      min-width: 25px;
      height: 25px;
      border-radius: 8px;
    }
    .balance-icon {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      @media (max-width: 1660px) {
        width: 14px;
        height: 14px;
      }
      svg {
        color: $white;
        width: 14px;
        height: 14px;
        vertical-align: unset;
        @media (max-width: 1660px) {
          width: 12px;
          height: 12px;
          vertical-align: text-top;
        }
      }
    }
  }
  .badge {
    padding: 8px 10px;
  }
  &.danger {
    .balance-icon-wrap {
      background-color: rgba($danger-color, 0.1);
      .balance-icon {
        background-color: $danger-color;
      }
    }
  }
  &.success {
    .balance-icon-wrap {
      background-color: rgba($success-color, 0.1);
      .balance-icon {
        background-color: $success-color;
      }
    }
  }
}
.chart-widget-top {
  h4 {
    font-size: 20px;
  }
  span {
    font-weight: 600;
  }
  .card-body {
    h6 {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.balance-box {
  .card-body {
    min-height: 360px;
  }
  .balance-profile {
    span {
      font-weight: 600;
    }
    h5 {
      font-size: 22px;
      font-weight: 700;
    }
  }
}
.visitor-card {
  .card-header {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--theme-default);
    }
  }
}
.visitors-container {
  margin: 0 -12px -27px -17px;
  svg {
    .apexcharts-series {
      path {
        clip-path: inset(1% 0% 0% 0% round 3rem);
      }
    }
    .apexcharts-legend.apexcharts-align-left {
      .apexcharts-legend-series {
        display: flex;
      }
    }
  }
}
.small-widget {
  overflow: hidden;
  h4 {
    margin-bottom: -3px;
    font-size: 24px;
    font-weight: 700;
  }
  i {
    font-weight: 700;
    font-size: 11px;
  }
  .card-body {
    padding: 24px 15px;
  }
  .bg-gradient {
    width: 66px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    right: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    [dir="rtl"] & {
      right: unset;
      left: -12px;
      transform: translateY(-50%) scaleX(-1);
    }
    @media (max-width: 1399px) {
      width: 60px;
      height: 60px;
    }
    svg {
      width: 25px;
      height: 25px;
      @media (max-width: 1399px) {
        width: 22px;
        height: 22px;
      }
    }
  }
  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
    .bg-gradient {
      svg {
        animation: tada 1.5s ease infinite;
      }
    }
  }
}
.radial-progress-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  &.card-body {
    padding: 23px 20px;
    @media (max-width: 1199px) {
      padding: 30px 20px;
    }
  }
  p {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: 600;
    font-size: 15px;
  }
  h6 {
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    font-size: 18px;
  }
  .sale-details {
    margin: 10px 0;
    svg {
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }
    h5 {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .radial-chart-wrap {
    width: 110px;
    margin-right: -25px;
    [dir="rtl"] & {
      margin-right: unset;
      margin-left: -25px;
    }
    @media (max-width: 1820px) {
      margin-right: -12px;
      [dir="rtl"] & {
        margin-left: -12px;
      }
    }
    .apexcharts-canvas {
      .apexcharts-datalabel-label {
        display: none;
      }
    }
  }
}
.widget-1 {
  background-image: url(../../images/dashboard/widget-bg.png);
  background-size: cover;
  margin-bottom: 25px;
  border-radius: 20px;
  i {
    font-weight: 700;
    font-size: 12px;
  }
  .f-w-500 {
    display: flex;
    align-items: center;
    @media (max-width: 1580px) and (min-width: 1200px) {
      display: none;
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 18px;
    @media (max-width: 1720px) {
      padding: 18px;
    }
  }
  .widget-round {
    position: relative;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-radius: 100%;
    .bg-round {
      width: 56px;
      height: 56px;
      box-shadow: $widget-shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin: 6px;
      position: relative;
      z-index: 1;
      svg {
        width: 24px;
        height: 24px;
      }
      .half-circle {
        height: 52px;
        position: absolute;
        left: -9px;
        width: 35px;
        bottom: -8px;
        background: $white;
        z-index: -1;
      }
      @media (max-width:1600px) {
        width: 40px;
        height: 40px;
        svg {
          width: 22px;
          height: 22px;
        }
        .half-circle {
          height: 40px;
          left: -10px;
          width: 30px;
          bottom: -8px;
        }
      }
    }
    &.primary {
      border-color: $primary-color;
    }
    &.secondary {
      border-color: $secondary-color;
    }
    &.success {
      border-color: $success-color;
    }
    &.warning {
      border-color: $warning-color;
    }
  }
  .widget-content {
    display: flex;
    align-items: center;
    gap: 15px;
    @media (max-width:1600px) {
      gap: 10px;
    }
    h4 {
      margin-bottom: 4px;
      font-size: 24px;
      font-weight: 700;
    }
    span {
      font-weight: 600;
    }
  }
  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
    .widget-round {
      .svg-fill:not(.half-circle) {
        animation: tada 1.5s ease infinite;
      }
    }
  }
}
.currency-chart-wrap {
  height: 90px;
}
.order-chart,
.profit-chart {
  height: 100px;
}
.course-box {
  overflow: hidden;
  .card-body {
    padding: 22px 15px;
    @media (max-width: 1722px) {
      padding: 16px 15px;
    }
    @media (max-width: 1660px) {
      padding: 14px 15px;
    }
  }
  &.widget-course {
    .card-body {
      @media (max-width: 1660px) {
        padding: 22px 15px;
      }
    }
  }
  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
    .course-icon {
      svg {
        animation: tada 1.5s ease infinite;
      }
    }
  }
}
.course-widget {
  display: flex;
  gap: 8px;
  .course-icon {
    position: relative;
    width: 54px;
    height: 54px;
    clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);
    background: linear-gradient(310.31deg, #FF6150 14.71%, #FF7F9E 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 26px;
      height: 26px;
      fill: $white;
    }
    &.warning {
      background: linear-gradient(297.08deg, #FF9E2A -4.53%, #FFA941 98.25%);
    }
  }
  .btn-light {
    background: var(--course-light-btn) !important;
    border: none;
  }
  h4 {
    font-size: 24px;
    font-weight: 700;
  }
  span {
    font-weight: 600;
  }
  a {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-top: 10px;
    span {
      display: block;
      margin-bottom: -5px;
    }
    svg {
      width: 13px;
      height: 13px;
    }
  }
}
.user-list {
  column-count: 2;
  column-rule-style: dashed;
  column-rule-color: rgba($theme-body-sub-title-color, 0.7);
  column-gap: 120px;
  column-rule-width: 1px;
  @media (max-width: 1780px) {
    column-gap: 80px;
  }
  @media (max-width: 1660px) {
    column-gap: 40px;
  }
  @media (max-width: 360px) {
    column-gap: 25px;
  }
  h5 {
    font-size: 20px;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    @media (max-width: 1540px) {
      gap: 5px;
    }
    i {
      font-size: 12px;
      font-weight: 700;
    }
    h5 {
      @media (max-width: 360px) {
        font-size: 16px;
      }
    }
    span {
      @media (max-width: 360px) {
        font-size: 13px;
      }
    }
    &:hover {
      .user-box {
        svg {
          animation: tada 1.5s ease infinite;
        }
      }
    }
  }
  .user-icon {
    border-radius: 100%;
    display: inline-block;
    .user-box {
      margin: 3px;
      width: 47px;
      height: 47px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1540px) {
        width: 30px;
        height: 30px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    &.primary {
      border: 1px solid $primary-color;
      .user-box {
        background: lighten($primary-color, 25%);
      }
    }
    &.success {
      border: 1px solid $success-color;
      .user-box {
        background: lighten($primary-color, 25%);
      }
    }
  }
}
.redial-social-widget {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 0 auto;
  background-image: linear-gradient(90deg, $light-color 50%, transparent 50%, transparent), linear-gradient(90deg, $light-gray 50%, $light-color 50%, $light-color);
  i {
    background-color: $white;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, var(--theme-deafult) 50%, transparent 50%, transparent), linear-gradient(270deg, var(--theme-deafult) 50%, $light-color 50%, $light-color);
  }
}
.social {
  &-img {
    padding: 5px;
    background: var(--white);
    border-radius: 100%;
  }
  &-widget {
    .card-body {
      padding: 15px;
    }
    .social-icons {
      min-width: 55px;
      height: 55px;
      background: var(--white);
      box-shadow: 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      [dir="rtl"] & {
        [class="dark-only"] & {
          background: $dark-card-background;
        }
      }
      img {
        margin-left: 0px;
      }
      @media (max-width: 1399px) {
        min-width: 40px;
        height: 40px;
        img {
          height: 20px;
        }
      }
    }
    .social-content {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      h5 {
        font-size: 22px;
        font-weight: 700;
      }
      span {
        font-weight: 600;
      }
      .social-chart {
        margin: -34px -42px -13px;
        width: 150px;
        .apexcharts-canvas {
          .apexcharts-datalabels-group {
            .apexcharts-datalabel-label {
              display: none;
            }
          }
        }
      }
    }
  }
}
.balance-box {
  text-align: center;
  // background-image: url(../../images/dashboard-2/balance-bg.png);
  background-position: right;
  background-size: cover;
  .balance-img {
    display: inline-block;
    padding: 5px;
    border: 2px solid var(--chart-border);
    border-left-color: $success-color;
    border-bottom-color: $success-color;
    border-radius: 100%;
    position: relative;
    margin-bottom: 15px;
    img {
      background-color: var(--balance-profie-bg);
      border-radius: 100%;
      margin-left: 0px;
    }
    .edit-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--white);
      background-color: #DFDFF4;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      svg {
        width: 11px;
        height: 11px;
        fill: $primary-color;
      }
    }
  }
}
.balance-profile {
  ul {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 16px;
    @media (max-width: 1660px) {
      gap: 25px;
    }
    li {
      position: relative;
      +li {
        &::before {
          position: absolute;
          content: '';
          left: -25px;
          top: 50%;
          height: 40px;
          transform: translateY(-50%);
          border: 1px dashed var(--recent-dashed-border);
          [dir="rtl"] & {
            left: unset;
            right: -25px;
          }
          @media (max-width: 1660px) {
            left: -16px;
            [dir="rtl"] & {
              right: -16px;
            }
          }
        }
      }
    }
  }
}
.widget-with-chart {
  .card-body {
    align-items: center;
    h4 {
      font-size: 24px;
      font-weight: 700;
    }
  }
}
// calendar

ngb-datepicker {
  border: 0 !important;
  .ngb-dp-header {
    background-color: transparent;
    padding: 4px 0 25px;
    .ngb-dp-arrow-btn {
      background-color: $primary-color;
      color: $white;
      width: 34px;
      height: 34px;
      margin: 0;
      border-radius: 30px;
      span {
        width: 8px;
        height: 8px;
      }
    }
  }
  .ngb-dp-weekday {
    color: $primary-color;
    font-weight: 600;
    font-style: initial;
  }
  .ngb-dp-content {
    .ngb-dp-week {
      .btn-light {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $theme-body-font-color;
        &:hover {
          background-color: $primary-color !important;
          border-color: $primary-color !important;
          color: $white;
          outline: 0;
          &.text-muted {
            color: $white !important;
          }
        }
        &.text-white {
          background-color: $primary-color !important;
          border: 1px solid $primary-color !important;
          color: $white !important;
        }
      }
    }
  }
  .ngb-dp-weekdays {
    background-color: transparent;
    border-bottom: 0;
  }
}
.cal-date-widget {
  .cal-datepicker {
    ngb-datepicker {
      width: 100%;
      padding: 0;
    }
    .ngb-dp-month {
      width: 100%;
      .ngb-dp-weekday,
      .ngb-dp-day {
        width: 100%;
        height: 38px;
      }
      .ngb-dp-day {
        > div[ngbdatepickerdayview] {
          width: 100%;
        }
      }
    }
  }
}
.ngb-dp-day {
  width: 100% !important;
  .btn-light {
    width: 100%;
  }
}
ngb-datepicker {
  width: 100%;
}
.ngb-dp-month {
  width: 100%;
}
.ngb-dp-weekday {
  width: 100% !important;
}
/**=====================
    3.11 General widget CSS Ends
==========================**/